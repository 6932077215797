import React from 'react'

const Title = ({ children, className, variant = 'default', underline = 'w-full h-4/10 left-0' }) => {
    const styles = {
        small: 'text-base',
        default: 'text-xl',
        medium: 'text-2xl font-bold',
        large: 'text-3xl font-bold',
        'extra-large': 'text-4xl font-black'
    }

    return (
        <p className={`${styles[variant]} relative z-10 leading-none ${className}`}>
            {children}
        </p>
    )
}

export default Title
